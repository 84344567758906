import React from 'react';
import './footer.css';

function Footer(){
    return <div className="footer">
        <span>Kyle Adrian Lainez</span>
        <span>2021</span>
    </div>
}

export default Footer;